<template>
    <div class="action-notice-approve-document">
        <div class="title f-bold" v-html="title" />
        <div class="body">
            <div v-html="body" />
        </div>
    </div>
</template>
<script>
export default {
    name: 'ActionNoticeVerification',
    props: ['message'],
    computed: {
        chat() {
            return this.$store.getters.chat
        },
        chatUser() {
            if (!this.chat) return

            return (this.chat || {}).user
        },
        title() {
            const contentObj = this.transformToJsObject(this.message.content)
            const verificationType = contentObj.verification_type
            let title
            if (verificationType === 'company') {
                title = this.$translate('ACTION_NOTICE_COMPANY_VERIFICATION_TITLE')
            } else {
                title = this.$translate('ACTION_NOTICE_SCHOOL_VERIFICATION_TITLE')
            }
            return title
        },
        body() {
            const contentObj = this.transformToJsObject(this.message.content)
            const verificationType = contentObj.verification_type
            const userName = contentObj.name
            let body
            if (verificationType === 'company') {
                body = this.$translate('ACTION_NOTICE_COMPANY_VERIFICATION_BODY')
                body = body.replace(/%s/, userName)
            } else {
                body = this.$translate('ACTION_NOTICE_SCHOOL_VERIFICATION_BODY')
                body = body.replace(/%s/, userName)
            }
            return body
        },
    },
    methods: {
        transformToJsObject(str) {
            let newStr = str.replace(/:(\s*)([a-z0-9_]+)(\s*)=>/gi, '"$2":')
            newStr = newStr.replace(/:(\s*)'([^']*)'/g, ': "$2"')
            newStr = newStr.replace(/nil/g, 'null')
            try {
                return JSON.parse(newStr)
            } catch (e) {
                console.error(e)
                return {}
            }
        },
    },
}
</script>
